<template>
    <section style="height: calc(100vh - 60px);">
        <div class="row mx-0 h-100">
            <div class="col text-center my-auto">
                <p class="text-hora my-3">{{ hora_local }}</p>
                <img width="200" src="/img/modales/online_shop.svg" alt="" />
                <div class="row mx-0 justify-center">
                    <div class="bg-gr-red cr-pointer text-white mt-4 text-center br-10 p-2" style="width:300px;" @click="abrir_tienda">
                        Abrir tienda
                    </div>
                </div>
                <div v-if="tienda.estado !== 5">
                    <div class="row mx-0 justify-center">
                        <div class="bg-gr-general cr-pointer text-white mt-2 text-center br-10 p-2" style="width:300px;" @click="ir_perfil">
                            Actualizar datos de la tienda
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <p class="mt-2" style="width:300px;">Recuerda ajustar los últimos detalles de tu perfil antes que tus clientes te vean.</p>
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalYaCasi" titulo="Leechero pendiente de publicación" no-aceptar icon="online-shop">
            <div class="row mx-0 justify-center mb-3">
                <div class="col-12 my-2">
                    <p class="text-muted2 text-center f-600 f-20">
                        ¡Ya Casi!
                    </p>
                </div>
                <div class="col-12">
                    <p class="text-muted2 text-center f-15">
                        Falta poco para que tu tienda este disponible para el publico, recuerda actualizar tus datos, crear los productos que vendes y estarás listo.
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
export default {
    middleware:['auth','home'],
    data(){
        return{
            hora:0,
            minutos:0,
            segundos:0,
            hora_local:'00:00:00'
        }
    },
    computed:{
        ...mapGetters({
            tienda:'tiendas/navbar/tienda',
        }),
        rol(){
            return this.$usuario.rol_nombre
        }
    },
    created(){
        var self = this
        // setInterval(()=>{
        //     self.actualizar_tiempo()
        // },1000)
        setInterval(()=>{
            self.actualizar_tiempo()
        },1000)
    },
    methods: {
        actualizar_tiempo(){
            this.hora_local = moment().format('hh:mm:ss a')
        },
        async abrir_tienda(){
            if(this.tienda.estado !== 5){
                this.$refs.modalYaCasi.toggle()
                return
            }
            await this.$store.dispatch('tiendas/navbar/abrir_tienda',this.tienda.id)
            this.$router.push({ name: `${rol}.pedidos` })

        },
        ir_perfil(){
            this.$router.push({name:`${this.rol}.conf.datosTienda`})
        },
    }
}
</script>

<style lang="css" scoped>
.text-hora{
    font-size: 35px;
    color: #0892DD;
}
</style>
